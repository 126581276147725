@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    body {
        --tw-bg-opacity: 1;
        background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
        font-family: 'Roboto', sans-serif;
    }
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    @media (min-width: 640px) {
        table {
          display: inline-table !important;
        }

        thead tr:not(:first-child) {
          display: none;
        }
    }

    td:not(:last-child) {
        border-bottom: 0;
    }

    th:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, .1);
    }

    table {
        @apply relative min-w-full divide-y table-auto bg-gray-600;
    }

    th {
        @apply px-3 py-1 text-left text-sm md:text-xs font-medium text-gray-100 uppercase tracking-wider;
    }

    td {
        @apply px-3 text-sm md:text-xs py-0 whitespace-pre-wrap;
    }

    thead tr {
        @apply w-full h-10 border-gray-300 border-b py-4 bg-gray-600;
    }

    tbody tr {
        @apply bg-gray-50 text-gray-600;
    }

    .btn {
        @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2;
    }

    .btn-indigo {
        @apply bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500;
    }

    .btn-orange {
        @apply bg-orange-600 hover:bg-orange-700 focus:ring-orange-500;
    }


    .menu {
        @apply cursor-pointer h-full flex items-center text-sm text-gray-800 hover:text-indigo-700 tracking-normal px-4;
    }

    .menu_active {
        @apply cursor-pointer h-full flex items-center text-sm text-indigo-700 tracking-normal border-indigo-700 px-4;
    }

    .button-pagination {
        @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50;
    }

    .button-pagination-next {
        @apply relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50;
    }

    .button-pagination-previous {
        @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50;
    }

    .modal-body {
        height: calc(100vh - 335px);
        overflow-y: auto;
    }

    .form-input {
        @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300
    }

    .form-input-disabled {
        @apply form-input bg-gray-200
    }

    .form-label {
        @apply inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm
    }

    .tab-active {
        @apply py-2 px-4 cursor-pointer bg-indigo-100 ease-in duration-150 rounded text-xs xl:text-sm leading-none text-center text-indigo-700
    }

    .tab {
        @apply py-2 px-4 cursor-pointer text-center bg-transparent hover:bg-indigo-50 ease-in duration-150 rounded text-xs xl:text-sm leading-none text-gray-600
    }

    .td-border {
        @apply border hover:bg-gray-100 p-3;
    }

    .label-form {
        @apply ml-2 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm;
    }

    .tooltip {
        @apply invisible absolute;
    }

    .has-tooltip:hover .tooltip {
        @apply visible z-50 centered
    }

    .centered {
        position: fixed;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .max-h-126 {
        max-height: 30rem;
    }

    .card-font {
        @apply text-gray-600 text-sm tracking-normal font-normal;
    }

}